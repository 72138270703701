var searchKeys = [{
  key: "username",
  placeholder: "搬运人username查询",
  input: true
}, {
  key: "time",
  placeholder: ["时间_开始", "时间_结束"],
  dates: true,
  attrs: {
    showTime: false,
    valueFormat: "YYYY-MM-DD"
  }
}, {
  key: "button",
  button: true
}];
var columns = [{
  title: "日期",
  dataIndex: "time",
  key: "time",
  width: 140,
  scopedSlots: {
    customRender: "time"
  }
}, {
  title: "搬运人",
  dataIndex: "markname",
  key: "markname",
  width: 140,
  scopedSlots: {
    customRender: "markname"
  }
}, {
  title: "搬运量",
  dataIndex: "carryquantity",
  key: "carryquantity",
  width: 140,
  scopedSlots: {
    customRender: "carryquantity"
  }
}, {
  title: "通过量",
  dataIndex: "carrypass",
  key: "carrypass",
  width: 140,
  scopedSlots: {
    customRender: "carrypass"
  }
}, {
  title: "驳回量",
  dataIndex: "carrynopass",
  key: "carrynopass",
  width: 140,
  scopedSlots: {
    customRender: "carrynopass"
  }
}, {
  title: "待审量",
  dataIndex: "carrycheck",
  key: "carrycheck",
  width: 140,
  scopedSlots: {
    customRender: "carrycheck"
  }
}];
var columns2 = [{
  title: "日期",
  dataIndex: "time",
  key: "time",
  width: 140,
  scopedSlots: {
    customRender: "time"
  }
}, {
  title: "操作人",
  dataIndex: "markname",
  key: "markname",
  scopedSlots: {
    customRender: "markname"
  }
}, {
  title: "操作量",
  dataIndex: "taggingquantity",
  key: "taggingquantity",
  scopedSlots: {
    customRender: "taggingquantity"
  }
}, {
  title: "优质量",
  dataIndex: "highqualityquantity",
  key: "highqualityquantity",
  scopedSlots: {
    customRender: "highqualityquantity"
  }
}, {
  title: "普通量",
  dataIndex: "ordinaryquantity",
  key: "ordinaryquantity",
  scopedSlots: {
    customRender: "ordinaryquantity"
  }
}, {
  title: "灰色量",
  dataIndex: "greyquantity",
  key: "greyquantity",
  scopedSlots: {
    customRender: "greyquantity"
  }
}, {
  title: "低质量",
  dataIndex: "lowqualityquantity",
  key: "lowqualityquantity",
  scopedSlots: {
    customRender: "lowqualityquantity"
  }
}];
var columns3 = [{
  title: "日期",
  dataIndex: "time",
  key: "time",
  width: 140,
  scopedSlots: {
    customRender: "time"
  }
}, {
  title: "操作人",
  dataIndex: "markname",
  key: "markname",
  scopedSlots: {
    customRender: "markname"
  }
}, {
  title: "操作量",
  dataIndex: "checkquantity",
  key: "checkquantity",
  scopedSlots: {
    customRender: "checkquantity"
  }
}, {
  title: "通过量",
  dataIndex: "passquantity",
  key: "passquantity",
  scopedSlots: {
    customRender: "passquantity"
  }
}, {
  title: "驳回量",
  dataIndex: "noquantity",
  key: "noquantity",
  scopedSlots: {
    customRender: "noquantity"
  }
}];
export { searchKeys, columns, columns2, columns3 };