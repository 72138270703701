import { POST, GET } from "@/utils/util";
var DataStatisticsApi = {
  // 内容搬运量统计
  getCarryStatisticsList: function getCarryStatisticsList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("api/communityservice/videolog/getcarrystatisticslist", params);
  },
  // 内容质量标操作统计
  getTaggingStatisticsList: function getTaggingStatisticsList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("api/communityservice/videolog/gettaggingstatisticslist", params);
  },
  // 内容审核操作统计
  getCheckStatisticsList: function getCheckStatisticsList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("api/communityservice/videolog/getcheckstatisticslist", params);
  }
};
export default DataStatisticsApi;